import { useState } from 'react'
import styles from './Footer.module.css'
import ReactModal from 'react-modal'

function Footer() {
  // Ouverture et fermeture de la modale
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)

  return (
    <footer role='contentinfo' className={styles.footer}>
      <div></div>
      <div>
        <p className={styles.copyright}><i className="fa-regular fa-copyright"></i> <a href='https://clairedevito.fr' rel="noreferrer" target="_blank">Claire De Vito</a> | 2023</p>
      </div>
      <ul className={styles.contact}>
        <li><button onClick={() => setIsOpen1(true)} aria-label='Ouverture des mentions légales' aria-haspopup aria-expanded={isOpen1} className={styles.button}>Mentions légales</button></li>
        <li><button onClick={() => setIsOpen2(true)} aria-label='Ouverture de la politique de confidentialité' aria-haspopup aria-expanded={isOpen2} className={styles.button}>Politique de confidentialité</button></li>
      </ul>
      <ReactModal
        isOpen={isOpen1}

        contentLabel={'Mentions légales'}
        /* String indicating how the content container should be announced
          to screenreaders */

        onRequestClose={() => setIsOpen1(false)}

        closeTimeoutMS={500}

        className={styles.modal}
        /* String className to be applied to the modal content.
          See the `Styles` section for more details. */
        >
          <div className={styles.modalContainer}>
            <button onClick={() => setIsOpen1(false)} aria-label='Fermeture des mentions légales' className={styles.buttonCloseForm}><i className={styles.icone + ' fa-solid fa-xmark fa-2xl'}></i></button>
            <section aria-label='Mentions légales' className={styles.section}>
              <h3 className={styles.underline}>Mentions légales :</h3>
              <ul>
                <li><span>Nom :</span> De Vito Béatrice</li>
                <li><span>Numéro d'ordre :</span> 14298</li>
                <li><span>Numéro de téléphone professionnel :</span> 06.23.38.21.07</li>
                <li><span>Adresse e-mail professionnelle :</span> beatrice.devito@neuf.fr</li>
                <li><span>Numéro SIRET :</span> 44121109100026</li>
                <li><span>Assurance responsabilité civile professionnelle :</span> Souscrite</li>
              </ul>
            </section>
          </div>
        </ReactModal>
        <ReactModal
        isOpen={isOpen2}

        contentLabel={'Politique de confidentialité'}
        /* String indicating how the content container should be announced
          to screenreaders */

        onRequestClose={() => setIsOpen2(false)}

        closeTimeoutMS={500}

        className={styles.modal}
        /* String className to be applied to the modal content.
          See the `Styles` section for more details. */
        >
          <div className={styles.modalContainer}>
            <button onClick={() => setIsOpen2(false)} aria-label='Fermeture de la politique de confidentialité' className={styles.buttonCloseForm}><i className={styles.icone + ' fa-solid fa-xmark fa-2xl'}></i></button>
            <section aria-label='Politique de confidentialité' className={styles.section}>
              <h3 className={styles.underline}>Politique de confidentialité :</h3>
              <p>Cette politique de confidentialité décrit comment nous collectons et utilisons les données personnelles que vous nous fournissez lorsque vous visitez notre site web.</p>
              <h4>Collecte et utilisation des données personnelles</h4>
              <p>Lorsque vous utilisez notre formulaire de contact, nous collectons les données personnelles suivantes : nom, prénom, téléphone, et e-mail. Ces informations sont collectées dans le but de vous recontacter et de répondre à votre demande. Nous utilisons ces données uniquement à des fins de communication avec vous et ne les partageons pas avec des tiers.</p>
              <h4>Conservation des données</h4>
              <p>Nous ne conservons vos données personnelles que pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées, sauf si une période de conservation plus longue est requise ou autorisée par la loi.</p>
              <h4>Vos droits</h4>
              <p>Vous avez le droit d'accéder à vos données personnelles, de les rectifier, de les supprimer, de limiter leur traitement et de vous opposer à leur traitement. Vous pouvez exercer ces droits en nous contactant à l'adresse e-mail indiquée ci-dessous.</p>
              <h4>Contact</h4>
              <p>Si vous avez des questions concernant notre politique de confidentialité ou si vous souhaitez exercer vos droits concernant vos données personnelles, veuillez nous contacter à l'adresse e-mail suivante : beatrice.devito@neuf.fr.</p>
            </section>
          </div>
        </ReactModal>
    </footer>
  )
}

export default Footer