import Section from '../../components/Section/Section'
import styles from './Consultation.module.css'

function Consultation() {
  return (
    <Section ariaLabel='Consultation' id='consultation'>
      <h2>Comment se déroule la consultation ?</h2>
      <p>La consultation dure en moyenne <em>1h</em>.</p>
      <p>Elle se déroule en plusieurs <em>étapes</em> :</p>
      <ul className={styles.list}>
        <li>prise de connaissance</li>
        <li>recueil du motif de consultation et des symptômes observés</li>
        <li>examen visuel</li>
        <li>recherche et traitement des dysfonctions ostéopathiques</li>
        <li>compte-rendu</li>
        <li>prescription si besoin, d'homéopathie ou de phytothérapie</li>
      </ul>
      <p>Dans les jours qui suivent, une fatigue ou d'autres symptômes peuvent apparaître, mais ces effets secondaires sont passagers et légers. En cas de doute, n'hésitez pas à me contacter.</p>
      <p>Un repos de 2 à 3 jours est recommandé après la séance ainsi qu'une reprise progressive de l'activité.</p>
      <p>P.S: aucun acte médical ne sera réalisé et aucun médicament allopathique ne sera prescrit et délivré.</p>
    </Section>
  )
}

export default Consultation