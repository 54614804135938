import styles from './Prices.module.css'
import { useRef, useState } from 'react'
import ReactModal from 'react-modal'
import emailjs from '@emailjs/browser'
import Section from '../../components/Section/Section'

ReactModal.setAppElement('#root')

function Prices() {
  // Envoi d'un e-mail via le formulaire de contact avec EmailJS
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_e6y5c0t', 'template_v16v4ah', form.current, 'dFhablydbCvc170xZ')
      .then(
        (result) => {
          console.log(result.text)
          document.getElementById('buttonSubmit').setAttribute('value', 'Envoyé !')
          document.getElementById('buttonSubmit').setAttribute('disabled', 'true')
        },
        (error) => {
          console.log(error.text)
          alert("Je suis navrée, il semble qu'il y ait une erreur. Merci de réessayer plus tard, ou de me contacter à l'adresse e-mail suivante : beatrice.devito@neuf.fr.")
        }
      )
  }

  // Ouverture et fermeture de la modale
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Section ariaLabel='Tarifs et contact' id='prices' styleSection={1}>
      <h2>Tarifs et contact</h2>
      <p>Les consultations s'effectuent sur <em>rendez-vous</em> :</p>
      <ul className={styles.list}>
        <li>soit à votre domicile ;</li>
        <li>soit chez votre vétérinaire traitant, sur demande de ce dernier.</li>
      </ul>
      <p>Le tarif est de <em>70€</em> pour une consultation dans un rayon de 30 km autour de Rennes.<br />
      Merci de me contacter pour une distance supérieure.</p>
      <p><em>Pour me contacter :</em></p>
      <ul className={styles.list}>
        <li>Par téléphone : {/Mobi/.test(navigator.userAgent) ? (<a className={styles.link} href='tel:+33623382107'>06.23.38.21.07</a>) : '06.23.38.21.07'}</li>
        <li>Par mail : <a className={styles.link} href='mailto:beatrice.devito@neuf.fr'>beatrice.devito@neuf.fr</a></li>
        <li>Ou via ce formulaire :<button onClick={() => setIsOpen(true)} aria-label='Ouverture du formulaire' aria-haspopup aria-expanded={isOpen} className={styles.buttonForm}><i className={styles.icone + ' fa-solid fa-pen-to-square fa-xl'}></i></button></li>
      </ul>
      <ReactModal
        isOpen={isOpen}

        contentLabel={'Formulaire de contact'}
        /* String indicating how the content container should be announced
          to screenreaders */

        onRequestClose={() => setIsOpen(false)}

        closeTimeoutMS={500}

        className={styles.modal}
        /* String className to be applied to the modal content.
          See the `Styles` section for more details. */
        >
          <div className={styles.modalContainer}>
            <button onClick={() => setIsOpen(false)} aria-label='Fermeture du formulaire' className={styles.buttonCloseForm}><i className={styles.icone + ' fa-solid fa-xmark fa-2xl'}></i></button>
            <section aria-label='Formulaire de contact' className={styles.sectionForm}>
              <form ref={form} onSubmit={sendEmail} className={styles.form}>
                <label htmlFor='form_name' className='visually-hidden'>Nom</label>
                <input className={styles.input} type='text' name='nom' pattern="^[a-zA-Z\s'\-]{2,25}$" minLength='2' maxLength='25' placeholder='Votre nom' aria-required='true' required />
                <label htmlFor='form_firstname' className='visually-hidden'>Prénom</label>
                <input className={styles.input} type='text' name='prenom' pattern="^[a-zA-Z\s'\-]{2,25}$" minLength='2' maxLength='25' placeholder='Votre prénom' />
                <label htmlFor='form_tel' className='visually-hidden'>Téléphone</label>
                <input className={styles.input} type='tel' name='telephone' pattern="^[\+0-9]([0-9\s\/\.\-]){5,19}$" minLength='6' maxLength='20' placeholder='Votre téléphone' aria-required='true' required />
                <label htmlFor='form_email' className='visually-hidden'>E-mail</label>
                <input className={styles.input} type='email' name='email' pattern="^[\w]([\w\-\.])*@([\w\-\.])+\.([\w]){2,4}$" minLength='6' maxLength='50' placeholder='Votre e-mail' aria-required='true' required />
                <label htmlFor='form_sujet' className='visually-hidden'>Sujet du message</label>
                <input className={styles.input} type='text' name='sujet' minLength='3' maxLength='50' placeholder='Sujet du message' />
                <label htmlFor='form_message' className='visually-hidden'>Message</label>
                <textarea name='message' minLength='10' maxLength='2000' placeholder='Votre message' aria-required='true' required></textarea>
                <input className={styles.buttonSubmit} id='buttonSubmit' type='submit' value='Envoyer' />
              </form>
            </section>
          </div>
      </ReactModal>
    </Section>
  )
}

export default Prices