import React, { useState, useEffect } from 'react'
import styles from './Header.module.css'

function Header() {
  // États du composant
  const [isOpen, setIsOpen] = useState(false) // État pour gérer l'ouverture/fermeture du menu
  const [animation, setAnimation] = useState(0) // État pour gérer l'animation
  const [activeLink, setActiveLink] = useState('') // État pour suivre la section active
  const [apparition1, setApparition1] = useState(1) // État pour gérer l'apparition de la première ligne du titre
  const [apparition2, setApparition2] = useState(0) // État pour gérer l'apparition de la deuxième ligne du titre
  const [disparition1, setDisparition1] = useState(0) // États pour gérer la disparition de la première ligne du titre
  const [disparition2, setDisparition2] = useState(1) // États pour gérer la disparition de la deuxième ligne du titre

  // Fonction pour ajouter une classe à la section visible
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const sections = document.querySelectorAll('section')
    let currentSectionId = ''

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 53
      const sectionBottom = sectionTop + section.offsetHeight

      if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
        currentSectionId = section.id
      }
    })

    setActiveLink(currentSectionId)
  }

  // Fonction pour basculer le menu ouvert/fermé
  const toggleMenu = () => {
    if (isOpen === true) {
      if (animation === 0) {
        setAnimation(1)
      } else {
        setAnimation(0)
      }
      setTimeout(() => {setIsOpen(false)}, 350)
    } else {
      setIsOpen(true)
      if (animation === 0) {
        setAnimation(1)
      } else {
        setAnimation(0)
      }
    }
  }

  // Fonction permettant le changement de ligne de titre du header
  useEffect(() => {
    const flipInterval = setInterval(() => {
      if (apparition1 === 0) {
        setApparition1(1)
        setApparition2(0)
        setTimeout(() => {setDisparition1(0)}, 700)
        setTimeout(() => {setDisparition2(1)}, 700)
      } else {
        setApparition1(0)
        setApparition2(1)
        setTimeout(() => {setDisparition1(1)}, 700)
        setTimeout(() => {setDisparition2(0)}, 700)
      }
    }, 7000)

    return () => {
      clearInterval(flipInterval)
    }
  }, [apparition1])

  // Fonction pour faire défiler la page vers le haut de manière fluide
  const scrollTo = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Ajoute un effet de défilement fluide
      })
  }

  return (
    <header role='banner' animation={animation} className={styles.header}>
      <button className={styles.buttonLogo} aria-label='Haut de page' onClick={scrollTo}>
        <h1 className={styles.h1}>
          <div apparition={apparition1} disparition={disparition1}><span className={styles.majuscule}>B</span>éatrice <span className={styles.majuscule}>D</span>e Vito</div>
          <div apparition={apparition2} disparition={disparition2}><span className={styles.majuscule}>O</span>stéopathie et <span className={styles.majuscule}>H</span>oméopathie</div>
        </h1>
      </button>
      <nav className={styles.nav} role='navigation' aria-label='Navigation principale'>
        <button
          aria-label='Bouton Menu'
          aria-controls='toggleNavbar'
          aria-haspopup
          aria-expanded={isOpen}
          animation={animation}
          onClick={toggleMenu}
          className={styles.iconeNav}
        >
          <div className={styles.navMenu + ' ' + styles.navMenuTop}></div>
          <div className={styles.navMenu + ' ' + styles.navMenuMiddle}></div>
          <div className={styles.navMenu + ' ' + styles.navMenuBottom}></div>
          <span className='visually-hidden'>Menu</span>
        </button>
        <div className={styles.containerNav}>
          <ul
            id='toggleNavbar'
            animation={animation}
            className={`${styles.ul} ${isOpen ? '' : styles.hideMenu}`}
          >
            <li className={styles.li}>
              <a
                onClick={toggleMenu}
                className={`${styles.a} ${activeLink === 'introduction' ? styles.active : ''}`}
                href='#introduction'
              >
                Présentation
              </a>
            </li>
            <li className={styles.li}>
              <a
                onClick={toggleMenu}
                className={`${styles.a} ${activeLink === 'consultation' ? styles.active : ''}`}
                href='#consultation'
              >
                Consultation
              </a>
            </li>
            <li className={styles.li}>
              <a
                onClick={toggleMenu}
                className={`${styles.a} ${activeLink === 'prices' ? styles.active : ''}`}
                href='#prices'
              >
                Tarifs et contact
              </a>
            </li>
            <li className={styles.li}>
              <a
                onClick={toggleMenu}
                className={`${styles.a} ${activeLink === 'about' ? styles.active : ''}`}
                href='#about'
              >
                À propos
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header