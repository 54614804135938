import { HashRouter } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { ParallaxProvider } from 'react-scroll-parallax'
import About from '../../pages/About/About'
import Consultation from '../../pages/Consultation/Consultation'
import Prices from '../../pages/Prices/Prices'
import Loader from '../Loader/Loader'
import Introduction from '../../pages/Introduction/Introduction'
const Banner = lazy(() => import('../Banner/Banner'))

function App() {
  return (
    <ParallaxProvider>
      <HashRouter>
        <Header />
        <main>
          <Suspense fallback={<Loader />}><Banner /></Suspense>
          <Introduction />
          <Consultation />
          <Prices />
          <About />
        </main>
        <Footer />
      </HashRouter>
    </ParallaxProvider>
  )
}

export default App
