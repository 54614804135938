import { useState, useEffect, useRef } from 'react'
import styles from './Section.module.css'
import { Parallax } from 'react-scroll-parallax'

function Section({ children, ariaLabel, id, styleSection }) {
  const [animation, setAnimation] = useState(1)
  const [visibleHeight, setVisibleHeight] = useState(0)

  const scrollPosRef = useRef(0)
  
  // Fonction animant la section visible uniquement lors du scroll vers le bas
  useEffect(() => {
    function handleScroll() {
      const sectionElement = document.getElementById(id)
      if (window.scrollY > scrollPosRef.current) {
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect()
          const isVisibleAnimation = rect.top + 100 <= window.innerHeight && rect.bottom >= 10
          isVisibleAnimation && setAnimation(2)
        }
      }
        
      scrollPosRef.current = window.scrollY
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Vérifie la visibilité initiale lors du montage du composant

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Fonction calculant la hauteur réelle de l'écran
  const getVisibleHeight = () => {
    let height = window.innerHeight
    setVisibleHeight(height)
  }

  useEffect(() => {
    getVisibleHeight()

    window.addEventListener('resize', getVisibleHeight)

    return () => {
      window.removeEventListener('resize', getVisibleHeight)
    }
  }, [])

  return (
    <section
      style={{ background: `${styleSection === 1 && 'rgb(228, 215, 181)'}`, minHeight: visibleHeight }}
      aria-label={ariaLabel}
      className={styles.section}
      id={id}
    >
      <div animation={animation} className={styles.deco}>
        <Parallax className={styles.parallax} rotate={[0, 360]}>
          <div className={styles.square}></div>
        </Parallax>
      </div>
      <div animation={animation} className={styles.bloc}>{children}</div>
    </section>
  )
}

export default Section
