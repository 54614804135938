import Section from '../../components/Section/Section'
import styles from './Introduction.module.css'

function Introduction() {
  return (
    <Section ariaLabel='Introduction' id='introduction' styleSection={1}>
      <h2>Présentation</h2>
      <p>Pour vos <em>animaux</em>, Docteur Vétérinaire, je pratique exclusivement l'<em>ostéopathie</em> et l'<em>homéopathie</em>.</p>
      <p>Pour <em>vous-même</em>, Ostéopathe D.O., je propose des séances d'ostéopathie à votre <em>domicile</em>.</p>
      <p>Je vous propose une consultation à domicile (proche <em>Ille-et-Vilaine</em>) notamment en cas de :</p>
      <ul className={styles.list}>
        <li>traumatismes</li>
        <li>troubles locomoteurs (boîterie, douleurs, ...)</li>
        <li>troubles neurologiques (névralgie, ...)</li>
        <li>problèmes organiques chroniques (toux, rhinites, vomissements, perturbations de l'appétit, diarrhées ou constipation, stérilité, infections urinaires, ...)</li>
        <li>pathologies comportementales (anxiété, hyperactivité, modifications de comportement, ...)</li>
        <li>pathologies dermatologiques (eczéma, ...)</li>
        <li>accompagnement du jeune (particulièrement lors de mal-occlusion dentaire), du senior, ou du chien de travail</li>
        <li>suivi post opératoire</li>
        <li>accompagnement d'un traitement allopathique</li>
      </ul>
    </Section>
  )
}

export default Introduction