import React, { useEffect, useState } from 'react'
import styles from './Loader.module.css'

// Loader visible au chargement du site le temps que la page s'affiche
function Loader() {
  const [visibleHeight, setVisibleHeight] = useState(0)

  // Fonction calculant la hauteur réelle de l'écran
  const getVisibleHeight = () => {
    let height = window.innerHeight
    setVisibleHeight(height - 73)
  }

  useEffect(() => {
    getVisibleHeight()

    window.addEventListener('resize', getVisibleHeight)

    return () => {
      window.removeEventListener('resize', getVisibleHeight)
    }
  }, [])

  return <div aria-hidden style={{ height: visibleHeight }} className={styles.page}><div className={styles.ldsGrid}><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div><div><i className='fa-solid fa-paw'></i></div></div></div>
}

export default Loader