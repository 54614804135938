import Section from '../../components/Section/Section'

function About() {
  return (
    <Section ariaLabel='A propos' id='about'>
      <h2>À propos de mon parcours</h2>
      <p>Depuis toute petite, le monde animal m'attire et c'est sans aucune hésitation que j'ai choisi le métier de vétérinaire. Le diplôme obtenu, je me suis orientée vers la médecine des petits animaux (chien, chat, NAC) et j'ai commencé à m'intéresser aux médecines complémentaires en raison de leur approche globale du malade et des possibilités de prévention, d'amélioration et même de guérison des maladies.</p>
      <p>Je me suis donc progressivement formée à l'<em>homéopathie</em> (2006-2009 FFSH), à la <em>phytothérapie</em> (2018 Wamine), et à l'<em>ostéopathie</em> (2009-2011 IMAOV) qui fut un tel coup de cœur que j'ai passé le diplôme d'ostéopathie humaine (2015 EFSO).</p>
      <p>Depuis janvier 2014, je ne pratique plus que ces médecines douces en proposant des séances pour des chiens, chats, NAC au domicile du propriétaire.</p>
      <p>Je propose également des séances d'<em>ostéopathie humaine</em> à domicile.</p>
    </Section>
  )
}

export default About